import React from  'react'
import { Link } from 'react-router-dom';
import logo from '../images/logo2.jpeg';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/free-solid-svg-icons';



function Navbar() {

return (
    <div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container-fluid">
            <Link to='/' className='navbar-brand' style={{ color: "#000000", fontSize: '30px' }}> <img className='logo' src={logo} alt="" style={{ width: '70px', height: '70px',fontFamily:'Helvetica'}} /> AI SAMHITHA</Link> 
            <br></br>
           
            <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-2">
                    <li className="nav-item">
                        <ScrollLink to="about" className="nav-link" smooth={true} duration={500}>About</ScrollLink>
                    </li>
                    <li className="nav-item">
                        <ScrollLink to="services" className="nav-link" smooth={true} duration={500}>Services</ScrollLink>
                    </li>
                    <li className="nav-item">
                        <ScrollLink to="faq" className="nav-link" smooth={true} duration={500}>FAQ</ScrollLink>
                    </li>

                    <li className="nav-item">
                        <ScrollLink to="contact" className="nav-link" smooth={true} duration={500}>Contact Us</ScrollLink>
                    </li>


                </ul>
            </div>
        </div>
    </nav>
    <div style={{ paddingTop: '70px' }}>
        {/* Add padding to prevent content from being hidden under the fixed navbar */}
        {/* Your content sections */}
    </div>
</div>
);






}

export default Navbar;