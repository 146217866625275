import React from  'react'

function Contact() {

return (
<div>

<br></br>
<br></br>
<br></br>

  <section >


    <div className='container' style={{height:'1000px'}}>
    <diV className='card Shadow'>
    <diV className='card-body'>
    <diV className='row'>



    <diV className='col-md-12 border-left'>
    <hr />
      {/*  <h6>Contact Form</h6>
       

       
      {/* <diV className='form-group'>
          <label className='mb-1'> Full Name </label>
          <input type='text'className='form-control' placeholder='Enter Full Name' />
    
      </diV>
 <diV className='form-group'>
          <label className='mb-1'> Mobile Number </label>
          <input type='text'className='form-control' placeholder='Mobile Number' />
    
      </diV>

      <diV className='form-group'>
          <label className='mb-1'> Email Address </label>
          <input type='text'className='form-control' placeholder='Email Address' />
    
      </diV>

      <diV className='form-group'>

      <label className='mb-1'> Message </label>
         
          <textarea rows="3" className='form-control' placeholder='Type your messsage...'></textarea>
      </diV>


      <diV className='form-group py-3'>
        
        <button type='button' className='btn btn-primary shawdow w-100'>Send Message</button>
      </diV>

 */}

<h2 >Contact us</h2>
  
<p>
  Contact Person : Vivek Suresh
  
  
  </p>
  

  <p>
  Mobile : +91 9986790440
  
  
  </p>
  
    
  <p>
  Email: vivekbs84@gmail.com
  
  
  </p>

</diV>




      </diV>





</diV>
      
    <div>
      
      
      </div>  


    </diV>

      
    </div>



  </section>
   
</div>

);

}

export default Contact;