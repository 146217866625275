import React, { useState, useEffect, useRef  } from 'react';
import ReactPlayer from 'react-player';

import { Link as ScrollLink } from 'react-scroll';
import Dropdown from '../Audio/Dropdown.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faPlayCircle,faPauseCircle, faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

import img1 from '../images/Mic.jpg';
import img2 from '../images/Wall of samples.jpg';
import img3 from '../images/mic2.jpg';
import img4 from '../images/mic3.jpg';
import img5 from '../images/mic4.jpg';
import img6 from '../images/mic5.jpg';



import gif from '../images/music-playing.gif'



function VideoPlayer({ src }) {
  return (
      <div className="card">
          <div className="card-body">
              <video muted webkit-playsinline controls controlsList="nodownload" width="100%">
                  <source src={src} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
          </div>
      </div>
  );
}




function Contact() {
  const [actors, setActors] = useState([]);
  const [activeActor, setActiveActor] = useState(null);
  const [audioPlayer, setAudioPlayer] = useState(new Audio());
  const [musicPlaying, setMusicPlaying] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.aisamhitha.com/api/ai_actor_audios'); // Replace with your actual API URL
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        setActors(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handlePlayPause = (actor) => {
    if (actor.audio_id === activeActor?.audio_id && !audioPlayer.paused) {
      audioPlayer.pause();
      setMusicPlaying(false);
      setCurrentLanguage('');
    } else {
      if (activeActor) {
        audioPlayer.pause(); // Pause the currently playing audio
        setCurrentLanguage('');
      }

      const newAudioPlayer = new Audio(actor.audio_link);
      newAudioPlayer.play();
      newAudioPlayer.addEventListener('ended', () => {
        setMusicPlaying(false);
        setCurrentLanguage('');
      });
      setAudioPlayer(newAudioPlayer);
      setActiveActor(actor);

       const interval = setInterval(() => {
        if (!audioPlayer.paused && actor.audio_id === activeActor?.audio_id) {
          const currentTime = Math.floor(audioPlayer.currentTime);
          const languageLabel = actor.languages.find((language) => {
            return currentTime >= language.start && currentTime <= language.end;
          });
          setCurrentLanguage(languageLabel ? languageLabel.language : '');
        } else {
          setCurrentLanguage('');
        }
      }, 1000);

      setMusicPlaying(true);
      return () => clearInterval(interval);
    }
  };

  return (
    <div id='about' className="container-fluid">
<br></br>
<br></br>



<section>
<div  class="card mb-3  border-0">
  <div className="row g-2">
  <div className="col-md-1">
    </div>
    <div className="col-md-5">
    <img style={{width:'100%', height:'100%', objectFit: 'cover'}} src={img1}/>
      
    </div>
    
    <div className="col-md-4" style={{paddingLeft:'5%'}}>
      <div className="card-body align-middle">
        <br></br>
        
       <h8 style={{textAlign:'Center'}}> India's Pioneer AI Voice Cloning Platform</h8>
   
    <br></br>
    <br></br>


        <h2  className="card-title" style={{paddingBottom:'4%'}}>Unleash the Power of Voice: Precision Cloning, Infinite Possibilities</h2>
        
        <h6 className="card-text" style={{fontStyle:'italic',paddingBottom:'4%'}}>From Any Language to Any Language</h6>
        <h7 className="card-text" >Unlocks the power of voice. Our bespoke voice cloning services  create tailored   vocal performances for films,documentaries  and TV series – adding depth, emotion and authenticity 
</h7>
      </div>
    </div>

  </div>

  

    </div>
  </section>      

  <section>


  <div  class="card mb-3  border-0">
  <div className="row g-2">
  <div className="col-md-1">
    </div>
 
    
    <div className="col-md-5" style={{paddingRight:'8%'}}>
      <div className="card-body align-middle">
        <br></br>

        <h2  className="card-title" style={{paddingBottom:'4%'}}>Voice Repertoire Repository</h2>
        
        <h6 className="card-text" style={{fontStyle:'italic',paddingBottom:'4%'}}>Explore our Top Notch Quality work by listening to our sample audios </h6>
        <h7 className="card-text" >1. Voice samples are only for illustration purpose. 
</h7>

<br></br>
<br></br>
      
<h7 className="card-text" >2. Diction and dubbing styles vary depending on the dubbing artist.
</h7>
      
      </div>
    </div>
  
    <div className="col-md-4" style={{paddingRight:'2%'}}>
    <img style={{width:'100%', height:'98%', objectFit: 'cover'}} src={img2}/>
      
    </div>


    <div className="row g-2 mt-1"  style={{paddingLeft:'8%',paddingRight:'18%'}}>
    
          {actors.map((actor) => (
            <div key={actor.audio_id} className="col-md-4 col-lg-4">
              <div className="card shadow mb-3 mb-md-0">
             
                <div className="card-body">
                  <div className="row align-items-center">
                    
                    <div className="col-2">
                      {actor.audio_id === activeActor?.audio_id && !audioPlayer.paused ? (
                        <FontAwesomeIcon icon={faPauseCircle} size="2x" onClick={() => handlePlayPause(actor)} />
                      ) : (
                        <FontAwesomeIcon icon={faPlayCircle} size="2x" onClick={() => handlePlayPause(actor)} />
                      )}
                    </div>
                    <div className="col-6">
                      <h5 className="m-0" style={{ fontSize: '13px', color: 'blue',textAlign:'right' }}>
                        {actor.actor_name}
                      </h5>
                        </div>

                    
                    
     
                    <div className="col-2" >
                  <img
                    src={actor.actor_image_path}
                    alt="Actor 2"
                    style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }}
                  />
                </div>
               
                  </div>
                </div>
                

              </div>

              <br></br>
            </div>
          ))}
        </div>

        

  </div>

  

    </div>
<br></br>





  </section>

    


  <section>


<div  class="card mb-3  border-0">
<div className="row g-2">
<div className="col-md-4">
  </div>

  
  
  <div className="col-md-5" style={{paddingRight:'8%'}}>
    <div className="card-body align-middle">
      

      <h2  className="card-title" >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Lip Sync <span style={{fontSize:'18px'}}> (Beta) </span></h2>
      
     
    
    </div>
  </div>

  

 <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <video
              muted
              playsInline
              loop
              controls
              src="https://api.aisamhitha.com/api/works-in-chrome-and-safari"
              style={{ width: '100%' }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <video
              muted
              playsInline
              loop
              controls
              src="https://api.aisamhitha.com/api/works-in-chrome-and-safari_new"
              style={{ width: '100%' }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
        
        <div id='services'></div>

        <div className="row">

  <div className="col-md-4"></div>

  <div className="col-md-6">
  <h10 className="card-text-bold" > **** Lip sync Video samples are only for illustration purpose. ****
</h10>

</div>
  
<br></br>
<br></br>
  
  </div>

     

</div>



  </div>
<br></br>





</section>

  

  <section>
  <div   className="row g-0" style={{marginTop:'1%'}}> 

  <div className="row g-0" style={{marginTop:'1%'}}> 

  <div className="col-md-12">
    <h1  style={{textAlign:'center'}}> Our Services </h1> 
  </div>

  </div>


  <div className="col-md-1"></div>  
  <div className="col-md-3 p-3">

  <h5 className="card-title" style={{textAlign:'center', marginBottom:'2%'}}>Custom Voice Generation</h5>
    <p className="card-text" style={{textAlign:'center'}}>We craft unique voices tailored to your specific character or project needs, ensuring perfect delivery and emotional resonance.</p>
    
    

    
  <div className="card bg-dark text-white border-0 g-0" style={{height:'400px'}}>
  <img style={{width:'100%', height:'100%', objectFit: 'cover'}} src={img3}/>
 
  <div className="card-img-overlay">
  <br></br>
   
     </div>
</div>


  </div>

  <div className="col-md-3 p-3">

  <h5 className="card-title" style={{textAlign:'center', marginBottom:'2%'}}>Seamless Audio Integration 
</h5>
    <p className="card-text" style={{textAlign:'center' }}> Our cloned voices blend flawlessly into existing audio tracks, creating a realistic and cohesive listening experience.  <br></br> 
</p>
    
  <div className="card bg-dark text-white border-0 g-0" style={{height:'400px'}}>
  <img style={{width:'100%', height:'100%', objectFit: 'cover'}} src={img4}/>
 
  <div className="card-img-overlay">
  <br></br>
   
     </div>
</div>

    
</div>


<div className="col-md-3 p-3">

<h5 className="card-title" style={{textAlign:'center', marginBottom:'2%'}}>Diverse Voice Portfolios

</h5>
    <p className="card-text" style={{textAlign:'center'}}> Explore a vast library of pre-made cloned voices or commission bespoke creations, offering flexibility and choice of your projects. 


</p>
  
<div className="card bg-dark text-white border-0 g-0" style={{height:'400px'}}>
  <img style={{width:'100%', height:'100%', objectFit: 'cover'}} src={img5}/>
 
  <div className="card-img-overlay">
  <br></br>
  
     </div>
     
</div>

    
</div>
  
  </div>



  </section>


<section>


<div className="row g-01" style={{marginTop:'1%'}}> 

<div className="col-md-12">

<div class="card mb-3  border-0">
  <div className="row g-2">
  <div className="col-md-1">
    </div>

    <div className="col-md-5">
    <img style={{width:'100%', height:'100%', objectFit: 'cover'}} src={img6}/>
      
    </div>
    <div className="col-md-5" style={{marginleft:"3%",marginRight:"3%"}}>
      <div className="card-body align-middle">
        <br></br>
      
        <h1 className="card-title" >How we do Voice Cloning</h1>
        <br></br>
        <h8 className="card-text"style={{fontWeight:'bold'}} >Step 1. Optimizing Voice Generation with Varied Datasets   </h8>
        <p>

        We meticulously curate datasets encompassing a range of combinations to ensure the precise incorporation of appropriate voice intonation and diction. The quality of the resultant output hinges upon the inclusion of clean voice recordings devoid of background music within these datasets.
        </p>
        

        <h8 className="card-text" style={{fontWeight:'bold'}} >Step 2. AI Advancements: Leveraging Neural Networks for Model Training </h8>
        <p>

        We employ cutting-edge Artificial Intelligence (AI) techniques, including Neural Networks, to facilitate deep learning and model training processes.
        </p>
        <h8 className="card-text" style={{fontWeight:'bold'}} >Step 3. Precision Voice Cloning: Adaptation of Dubbing Voice for Artist Models</h8>
        <p>After constructing the artist model, our process involves leveraging the dubbing voice to precisely clone and adapt it to match the unique vocal characteristics of the desired artist. This meticulous approach ensures a seamless integration of the desired voice into the final product.

Note: The quality of voice cloning is contingent upon the dubbing artist's proficiency in replicating accents and intonation. </p>

        </div>
    </div>
  </div>
</div>
  



</div>

</div> 
  
</section>

 

<section>

<div className="row g-0"> 
        <h1 id='faq' style={{color:'black', textAlign:'Center'}}>FAQs</h1>
        <br></br>
        <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button class="accordion-button"
         
         type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="false"
                        aria-controls="collapseOne">
                       What services does your voice cloning service offer?
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Our voice cloning service specializes in replicating celebrity voices for use in films, web series, and documentaries. This affords audiences the distinct opportunity to hear their favorite stars speak in their native languages.
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button class="accordion-button collapsed" 
                    type="button" data-bs-toggle="collapse" 
                    data-bs-target="#collapseTwo"    aria-expanded="false" aria-controls="collapseTwo">
                       How can I initiate the process?
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    To begin, you will require a No Objection Certificate (NOC) from both the production house and the respective celebrity whose voice you intend to clone.

                   </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button class="accordion-button collapsed"
                        type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false"
                        aria-controls="collapseThree">
                       Is there an opportunity to preview before committing?
                    </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    Certainly! We provide 15-30 second samples to demonstrate the efficacy of our voice cloning capabilities.

                    </div>
                </div>



            </div>


            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button class="accordion-button collapsed"
                        type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false"
                        aria-controls="collapseFour">
                        Does the AI handle the dubbing process?

                    </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    No, our service focuses solely on voice cloning. The dubbing process is conducted by skilled artists who provide the necessary intonation and accent, while our technology ensures a seamless match to the original voice.

                    </div>
                </div>



            </div>



            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed"
                        type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false"
                        aria-controls="collapseFive">
                       Could you outline the procedure for me?


                    </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                    <h6 className="card-text">Certainly - </h6> 
                    <h7 className="card-text"> * Submit a 30-minute sample of the original celebrity voice. </h7> <br></br>
                    <h7 className="card-text">* We will generate a voice model based on the provided sample.  </h7> <br></br>
                    <h7 className="card-text">* Forward your dubbed audio to us. </h7> <br></br>
                    <h7 className="card-text">* We will clone the celebrity's voice onto the dubbed track, ensuring a faithful reproduction.</h7> <br></br>



                    </div>
                </div>



            </div>



        </div>
    </div>



</section>


<section id='contact'>

<br></br>


<div className="row g-0"> 
        <h1 id='contact' style={{color:'black', textAlign:'Center'}}>Contact Us</h1>
        <br></br>
        <br></br>



    <div className="col-md-1">
     </div> 
    

     <div className="col-md-4">
    <h5> &nbsp; &nbsp; &nbsp; &nbsp;  <FontAwesomeIcon icon={faPhone} />&nbsp; +91 9986790440</h5>

    <br></br>
  
    <h5>&nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;<FontAwesomeIcon icon={faEnvelope}/> &nbsp;info@aisamhitha.com</h5>
      
    </div> 





    </div>



</section>

<footer className="bg-secondry text-center text-white p-2">
            <div className="container">
                <p className="mb-0" style={{color:'black', fontSize:'15px'}} >© {new Date().getFullYear()} AI SAMHITHA. All rights reserved.</p>
            </div>
        </footer>
        
      </div>

      

  );
}

export default Contact;