import React from  'react'

function About() {

return (
<div>
   

<section style={{backgroundColor:'#d0d4d9', marginTop:'50px'}} >
      <div className="container mt-5">
      <br></br>
      
      
      
      <div className="row">

      <div className="col-md-1"></div>
      <div className="col-md-10">

      <h2 className="mb-4 text-center" style={{color:'#3534af', fontSize:'50px'}}>About US</h2>
              
                <p  style={{textAlign:'justify',color:'black',fontSize:'20Px'}}>

                Welcome to <span style={{color:'#3534af',fontWeight:'bold'}}>AI SAMHITHA </span>, where innovation meets narration. We are a cutting-edge AI-powered voice-over service, dedicated to bringing your projects to life with seamless audio experiences in any language. <br></br>
                <br></br>


At <span style={{color:'#3534af',fontWeight:'bold'}}>AI SAMHITHA </span>, we understand the power of voice. Whether you're producing a commercial, documentary, audiobook, or any other multimedia content, the voice behind your project is crucial in conveying the right message and emotions to your audience. That's why we've developed state-of-the-art artificial intelligence technology to deliver high-quality voice-over solutions tailored to your needs.
<br></br><br></br>

Our AI system is capable of mimicking human speech patterns and nuances with remarkable accuracy, ensuring natural-sounding voice-overs that captivate listeners. With a vast library of voices in multiple languages and dialects, we offer unparalleled versatility to cater to diverse audiences worldwide.
<br></br><br></br>

But what sets us apart is our commitment to quality and convenience. Unlike traditional voice-over services that rely on human actors and lengthy production schedules, our AI-powered platform delivers fast turnaround times without sacrificing quality. Say goodbye to time-consuming casting sessions and tedious revisions—our streamlined process ensures efficient project delivery without compromising on excellence.
<br></br><br></br>

With <span style={{color:'#3534af',fontWeight:'bold'}}>AI SAMHITHA </span> the possibilities are endless. Break down language barriers, reach new audiences, and elevate your content to new heights with our revolutionary AI voice-over technology. Join the future of narration today.

<br></br>
                
                <br></br>
                </p>
        
                </div>

                <div className="col-md-1">

                
                </div>
                

      </div>
      

    </div>

    </section>


</div>

);

}

export default About;