import React from 'react'

import './App.css'
import Home from './components/pages/Home'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Navbar from './components/inc/Navbar'
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom'





function App() {
  return (
    <Router>
    <div>
    { <Navbar /> }
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      
    </Routes>
    </div>
    </Router>
  );
}

export default App;
